import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
  TextField,
  Button,
  IconButton,
  Backdrop
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useAuth } from 'oidc-react';
import { useTranslation } from "react-i18next";
import { Box, FormControl, InputLabel, Select, MenuItem, Snackbar, Alert, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SearchProductModual from './SearchProductModual';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const SubMaterialModal = ({ open, onClose, mainProductId, wono, counter, setCounter, postings }) => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [type, setType] = useState("");
  const [unit, setUnit] = useState("");
  const [comment, setComment] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [commentError, setCommentError] = useState(false);
  const [menuMaxHeight, setMenuMaxHeight] = useState('250px');
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");
  const auth = useAuth();
  const accessToken = auth.userData?.access_token;
  const optionsType = [
    { value: 'P', name: t('workOrder.materials.subMaterials.time') },
    { value: 'X', name: t('workOrder.materials.subMaterials.productMaterials') }];
  const [toastState, setToastState] = useState({
    open: false,
    severity: '',
    message: '',
  });
  const [diaryRequired, setDiaryRequired] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [article, setArticle] = React.useState([]);
  const [showSearchProduct, setShowSearchProduct] = React.useState(false);
  const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
  const preferred_username = auth.userData?.profile?.preferred_username?.toUpperCase();
  const onSeek = () => {
    setShowSearchProduct(!showSearchProduct);
  }

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const url = `${rootUrl}WorkOrder/planning/${wono}`;
        console.log("URL planning = " + url);
        const response = await fetch(url, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });

        if (!response.ok) {
          throw new Error("Failed to fetch most used products.");
        }

        const data = await response.json();
        setProducts(data);

      } catch (error) {
        console.error(error);
      }
    };
    fetchProducts();
    const screenHeight = window.innerHeight;
    const calculatedMaxHeight = `${screenHeight * 0.35}px`;
    setMenuMaxHeight(calculatedMaxHeight);
  }, [postings]);

  useEffect(() => {
    const fetchDiaryRequirement = async () => {
      try {
        const url = `${rootUrl}WorkOrder/agreementRule/${wono}?ruleKey=DIARYONAGREE`;
        const response = await fetch(url, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });

        if (response.status === 204) {
          return
        }
        else if (response.ok) {
          const data = await response.json();

          if (data !== null && data.ruleKey === 'DIARYONAGREE') {
            setDiaryRequired(true);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchDiaryRequirement();
  }, [accessToken]);
  
  useEffect(() => {
    if ((!article || (article && article.length < 1)) && type === "P") {
      setArticle([{
        "planLineNo": -1,
        "workOrderCostType": "P",
        "catalogNo": t('workOrder.materials.subMaterials.workingHours'),
        "lineDescription": "(h)"
      }]);
    } else if (article && article.length === 1 && article[0].catalogNo === "RESA INOM ARBETSTID") {
      setArticle([...article, {
        "planLineNo": -1,
        "workOrderCostType": "P",
        "catalogNo": t('workOrder.materials.subMaterials.workingHours'),
        "lineDescription": "(h)"
      }]);
    }
  }, [article]);

  useEffect(() => {
    if (products && type !== "") {
      const catalogNoSet = new Set();
      let filtered = [];
      setSelectedDate(new Date());
      if (type === "P") {
        setUnit("hour");
        filtered = products.filter(product => {
          if (product.workOrderCostType === "P" && product.catalogNo !== "") {
            if (!catalogNoSet.has(product.catalogNo)) {
              catalogNoSet.add(product.catalogNo);
              return true;
            }
          }
          return false;
        });
      } else {
        setUnit("");
        filtered = products.filter(product => {
          if (product.workOrderCostType !== "P") {
            if (!catalogNoSet.has(product.catalogNo)) {
              catalogNoSet.add(product.catalogNo);
              return true;
            }
          }
          return false;
        });
      }
      setArticle(filtered);
    }
  }, [type]);

  const addproduct = () => {
    setIsProcessing(true);
    let article = null;
    let costtype = null;
    if (selectedProduct && selectedProduct.value.catalogNo !== "" && selectedProduct.value.planLineNo !== -1) {
      article = selectedProduct.value.catalogNo;
    }
    if (type === "P") {
      costtype = "P";
    } else {
      costtype = selectedProduct.value.workOrderCostType;
    }
    const woData =
    {
      wO_NO: wono,
      performeD_ACTION_LO: diaryRequired === false ? comment : null,
      worK_ORDER_CODINGS: [
        {
          cataloG_NO: article,
          qtY_TO_INVOICE: quantity,
          qty: quantity,
          emP_NO: preferred_username,
          diarY_NOTE: diaryRequired === true ? comment : "",
          worK_ORDER_COST_TYPE: costtype,
          comment: comment,
          crE_DATE: selectedDate ? selectedDate.toISOString().split("T")[0] : null
        }
      ]
    };
    updateWorkOrder(woData);
  }

  const updateWorkOrder = async (woData) => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");
    const options = {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(woData)
    };
    let url = `${rootUrl}WorkOrder/wo`;
    const response = await fetch(url, options);

    if (response.ok) {
      if (comment !== "") {
        const diaryRow = {
          woNo: wono,
          userId: preferred_username,
          createdBy: preferred_username,
          diaryText: comment,
          diaryTime: quantity,
          userRole: "TEC",
          visible: true
        };

        if (diaryRequired === true) {
          addNewDiaryRow(diaryRow);
        } else {
          setToastState({
            open: true,
            severity: 'success',
            message: t('updateSuccess'),
          });
        }
      } else {
        setToastState({
          open: true,
          severity: 'success',
          message: t('updateSuccess'),
        });
      }
      setCounter(0);
      setComment("");
    }
    else {
      setToastState({
        open: true,
        severity: 'error',
        message: t('updateFail'),
      });
    }
    setIsProcessing(false);
  };

  const addNewDiaryRow = async (diaryRow) => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");
    const options = {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(diaryRow)
    };
    let url = `${rootUrl}Diary/add`;
    const response = await fetch(url, options);

    if (response.ok) {
      setToastState({
        open: true,
        severity: 'success',
        message: t('updateSuccess'),
      });
    }
    else {
      setToastState({
        open: true,
        severity: 'error',
        message: t('updateFail'),
      });
    }
  };

  const increaseQuantity = () => {
    setQuantity(prev => prev + 0.25);
  }

  const decreaseQuantity = () => {
    if (quantity > 0) {
      setQuantity(prev => prev - 0.25);
    }
  }

  const handleType = (event) => {
    setSelectedProduct(null);
    setType(event.target.value);
  };

  const handleOnChangeProductTime = (event, value) => {
    if (value) {
      setSelectedProduct(value);
    }
  };

  const handleOnClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setSelectedProduct(null);
      setQuantity(1);
      setType("");
      setComment("");
      setSelectedDate(new Date());
      onClose();
    }
  };

  const isOptionEqualToValue = (option, value) => option.value === value.value;
  

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth={true}
      style={{ display: showSearchProduct ? 'none' : '' }}
      slots={{ backdrop: Backdrop }}
    >
      <DialogTitle> {t('workOrder.materials.subMaterials.title')}</DialogTitle>

      <DialogContent>
        <Box sx={{ minWidth: 220, marginTop: 1 }}>
          <FormControl fullWidth>
            <InputLabel id="select-type">{t('workOrder.materials.subMaterials.selectType')}</InputLabel>
            <Select
              labelId="select-type"
              id="select-id-type"
              value={type}
              label={t('workOrder.materials.subMaterials.selectType')}
              onChange={handleType}
            >
              {optionsType !== null ? (
                optionsType.map((row, key) => (
                  <MenuItem key={key} value={row.value}>{row.name}</MenuItem>
                ))) : (<MenuItem value={0}>---</MenuItem>)}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: 220, marginTop: 1 }}>
          <Autocomplete
            id="combo-box-product"
            //options={article}
            options={article.map((article) => ({
              value: article,
              label: `${article.catalogNo} - ${article.lineDescription}`
            }))}
            fullWidth
            value={selectedProduct}
            ListboxProps={{ style: { maxHeight: menuMaxHeight } }}
            onChange={handleOnChangeProductTime}
            isOptionEqualToValue={isOptionEqualToValue}
            renderInput={(params) => (<TextField {...params}
              label={type === 'P' ? t('workOrder.materials.subMaterials.selectSalePartTime') : t('workOrder.materials.subMaterials.selectSalePart')}
              variant="outlined"
            />
            )}
          />
        </Box>

        {/* Quantity Counter */}
        <Box sx={{ minWidth: 220, marginTop: 1 }}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <IconButton onClick={decreaseQuantity}>
                <RemoveIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <TextField
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(Math.max(0, + e.target.value))}
                style={{ width: '85px', textAlign: 'center' }}
              />
            </Grid>
            {
              unit !== "" && (
              <Grid item>
                <span style={{ marginLeft: '-2px', marginRight: '-6px', fontSize: '1.2rem' }}>{t(`units.${unit}`)}</span>
              </Grid>
              ) 
            }
            <Grid item>
              <IconButton onClick={increaseQuantity}>
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        <Box component="div" sx={{ display: type === 'P' ? "flex" : "none", alignItems: 'center', margin: '16px 0 0 0' }}>
          <DatePicker 
            label={t('workOrder.materials.subMaterials.reportTime')} 
            sx={{ width: '100%' }}
            value={selectedDate} 
            onChange={(newValue) => setSelectedDate(newValue)}
            PopperProps={{
              placement: 'top-start'
            }}
          />
        </Box>
        <Box component="div" sx={{ display: type === 'P' ? "flex" : "none", alignItems: 'center' }}>
          <TextField
            label={t('workOrder.materials.subMaterials.note')}
            fullWidth
            margin="normal"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            error={commentError}
            helperText={diaryRequired ? t('workOrder.materials.subMaterials.noteHelperTextDiary') : t('workOrder.materials.subMaterials.noteHelperText')}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <SearchProductModual open={showSearchProduct} onClose={onSeek} wono={wono} setToastState={setToastState} setCounter={setCounter} />
        <Button onClick={onSeek} color="primary">
          <SearchIcon />{t('workOrder.materials.subMaterials.searchProduct')}
        </Button>
        <Button onClick={addproduct} color="primary" disabled={isProcessing || 
          (comment.length < 1 && type === 'P') || 
          (type === 'X' && !selectedProduct) || type === ""}>
          {t('add')}
        </Button>
        <Button onClick={handleOnClose} color="primary">
          {t('close')}
        </Button>
      </DialogActions>
      <Snackbar
        open={toastState.open}
        autoHideDuration={6000}
        onClose={() => setToastState({ ...toastState, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Position and alignment
        style={{ position: 'absolute', zIndex: 1301 }} // Adjust zIndex to be above other elements
      >
        <Alert
          severity={toastState.severity}
          variant="filled"
          onClose={() => setToastState({ ...toastState, open: false })}
        >
          {toastState.message}
        </Alert>
      </Snackbar>
    </Dialog>
  );
}

export default SubMaterialModal;
