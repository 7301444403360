import i18n from 'i18next';

const statuses = {
    FAULTREPORT: { stateName: 'workOrder.statuses.faultReport', stateColor: "#999999", objState: "FAULTREPORT"},
    OBSERVED: { stateName: 'workOrder.statuses.observed', stateColor: "#6FA8DC", objState: "OBSERVED" },
    WORKREQUEST: { stateName: 'workOrder.statuses.workRequest', stateColor: "#999999", objState: "WORKREQUEST" },
    UNDERPREPARATION: { stateName: 'workOrder.statuses.underpreparation', stateColor: "#E69138", objState: "UNDERPREPARATION" },
    PREPARED: { stateName: 'workOrder.statuses.prepared', stateColor: "#F5F599", objState: "PREPARED" },
    RELEASED: { stateName: 'workOrder.statuses.released', stateColor: "#F89088", objState: "RELEASED" },
    STARTED: { stateName: 'workOrder.statuses.started', stateColor: "#A8E7EA", objState: "STARTED" },
    JOB_START: { stateName: 'workOrder.statuses.onSite', stateColor: "#4DA6BB", objState: "JOB_START" },
    TEC_READY: { stateName: 'workOrder.statuses.fieldReady', stateColor: "#88DE92", objState: "TEC_READY" },
    CUSTOMER_READY: { stateName: 'workOrder.statuses.customerReady', stateColor: "#88DE92", objState: "CUSTOMER_READY" },
    WORKDONE: { stateName: 'workOrder.statuses.workDone', stateColor: "#849AE0", objState: "WORKDONE" },
    CANCELLED: { stateName: 'workOrder.statuses.cancelled', stateColor: "#FF3737", objState: "CANCELLED" },
    REPORTED: { stateName: 'workOrder.statuses.reported', stateColor: "#849A10", objState: "REPORTED" },
    FINISHED: { stateName: 'workOrder.statuses.finished', stateColor: "#BB2B73", objState: "FINISHED" }
};

const getStatusWithTranslation = (status) => ({
    ...status,
    stateName: i18n.t(status.stateName)
});

export function statusMapping(objState) {
    return statuses[objState] ? getStatusWithTranslation(statuses[objState]) : { stateName: "", stateColor: "" };
}

const statusOptions = {
    PREPARED: [statuses.RELEASED, statuses.STARTED],
    RELEASED_PREPARED: [statuses.PREPARED, statuses.STARTED],
    RELEASED_STARTED: [statuses.JOB_START, statuses.STARTED],
    RELEASED_JOB_START_BUILD: [statuses.JOB_START, statuses.TEC_READY],
    RELEASED_JOB_START_SERVICE: [statuses.JOB_START, statuses.CUSTOMER_READY],
    STARTED: [statuses.RELEASED, statuses.JOB_START],
    JOB_START__BUILD: [statuses.RELEASED, statuses.TEC_READY],
    JOB_START__SERVICE: [statuses.RELEASED, statuses.CUSTOMER_READY],
    JOB_START_STARTED_BUILD: [statuses.RELEASED, statuses.TEC_READY],
    JOB_START_STARTED_SERVICE: [statuses.RELEASED, statuses.CUSTOMER_READY],
    CUSTOMER_READY__SERVICE: [statuses.WORKDONE]
};

const elevatedStatusOptions = {
    FAULTREPORT: [statuses.OBSERVED, statuses.RELEASED],
    WORKREQUEST: [statuses.OBSERVED, statuses.RELEASED],
    OBSERVED: [statuses.FAULTREPORT, statuses.UNDERPREPARATION, statuses.RELEASED],
    UNDERPREPARATION: [statuses.OBSERVED, statuses.PREPARED, statuses.RELEASED],
    PREPARED: [statuses.UNDERPREPARATION, statuses.STARTED, statuses.RELEASED],
    RELEASED_PREPARED: [statuses.PREPARED],
    RELEASED_STARTED: [statuses.JOB_START, statuses.STARTED],
    RELEASED_JOB_START_BUILD: [statuses.JOB_START, statuses.TEC_READY],
    RELEASED_JOB_START_SERVICE: [statuses.JOB_START, statuses.CUSTOMER_READY],
    STARTED: [statuses.RELEASED, statuses.JOB_START],
    JOB_START__BUILD: [statuses.RELEASED, statuses.TEC_READY],
    JOB_START__SERVICE: [statuses.RELEASED, statuses.CUSTOMER_READY],
    JOB_START_STARTED_BUILD: [statuses.RELEASED, statuses.TEC_READY],
    JOB_START_STARTED_SERVICE: [statuses.RELEASED, statuses.CUSTOMER_READY],
    CUSTOMER_READY__SERVICE: [statuses.WORKDONE],
    TEC_READY__BUILD: [statuses.CUSTOMER_READY],
    CUSTOMER_READY__BUILD: [statuses.WORKDONE],
    WORKDONE: [statuses.REPORTED]
};

const getStatusOptions = (key, isElevated) => {
    const availableStatuses = (isElevated ? elevatedStatusOptions[key] : statusOptions[key]) || [];
    const availableStateNames = new Set(availableStatuses.map(status => status.stateName));

    let allStatuses = [];

    if (isElevated) {
        allStatuses = Object.values(statuses).map(status => ({
            ...getStatusWithTranslation(status),
            disabled: !availableStateNames.has(status.stateName)
        }));
    } else {
        allStatuses = availableStatuses.map(getStatusWithTranslation);
    }

    const hasFaultReport = allStatuses.some(status => status.objState === 'FAULTREPORT');
    const hasWorkRequest = allStatuses.some(status => status.objState === 'WORKREQUEST');

    // Fault report and work request statuses are pretty much the same, we only want one of them in the resulting list
    if (hasFaultReport && hasWorkRequest) {
        allStatuses = allStatuses.filter(status => !(statuses[status.objState] === statuses.WORKREQUEST));
    }

    return allStatuses;
};

export function selectableStatuses(objState, contractType, previousState = "", eventControl = "", isElevated = false) {
    if (eventControl === "PENDING") return [];

    const transitionKey = `${objState}_${previousState}_${contractType}`;
    const transitionStatusKey = `${objState}_${previousState}`;
    const basicStatusKey = `${objState}`;

    if ((isElevated && elevatedStatusOptions[transitionKey]) || statusOptions[transitionKey]) {
        return getStatusOptions(transitionKey, isElevated);
    }

    if ((isElevated && elevatedStatusOptions[transitionStatusKey]) || statusOptions[transitionStatusKey]) {
        return getStatusOptions(transitionStatusKey, isElevated);
    }

    if ((isElevated && elevatedStatusOptions[basicStatusKey]) || statusOptions[basicStatusKey]) {
        return getStatusOptions(basicStatusKey, isElevated);
    }

    return [];
}
