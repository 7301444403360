import React, { useState, useEffect } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Group,
  Inject,
  Page,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { useAuth } from "oidc-react";
import { useTranslation } from "react-i18next";

const HistoryLogCombined = ({ wono }) => {
  const auth = useAuth();
  const accessToken = auth.userData?.access_token;
  const { t } = useTranslation();
  const [historyLog, setHistoryLog] = useState([]);

  useEffect(() => {
    const fetchHistoryLog = async () => {
      try {
        const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
        const response = await fetch(
          `${rootUrl}WorkOrder/combinedhistorylog/${wono}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.ok) {
          const jsonData = await response.json();
          setHistoryLog(jsonData);
        } else {
          console.error("Error fetching data. Status:", response.status);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchHistoryLog();
  }, []);

  const pageSettings = { pageSize: 50 };

  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toISOString().replace("T", " ").substring(0, 19);
  }

  return (
    <div>
      <GridComponent
        dataSource={historyLog}
        allowPaging={true}
        pageSettings={pageSettings}
        allowSorting={true}
        allowFiltering={true}
        filterSettings={{ type: "Excel" }}
        allowTextWrap={true}
        textWrapSettings={{ wrapMode: 'Content' }}
        locale="sv"
      >
        <ColumnsDirective>
          <ColumnDirective
            field="timeStamp"
            headerText={t('workOrder.historyLog.time')}
            width="57"
            template={(props) => <span>{formatDate(props.timeStamp)}</span>}
          />
          <ColumnDirective field="category" headerText={t('workOrder.historyLog.category')} width="65" />
          <ColumnDirective
            field="username"
            headerText={t('workOrder.historyLog.change')}
            width="58"
            template={(props) => <span>{props.username.toUpperCase()}</span>}
            toolTip="test"
          />
          <ColumnDirective
            field="information"
            headerText={t('workOrder.historyLog.info')}
            width="180"
          />
        </ColumnsDirective>
        <Inject services={[Page, Sort, Filter]} />
      </GridComponent>
    </div>
  );
};

export default HistoryLogCombined;
