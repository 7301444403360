import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Tab,
  Tabs,
  Typography,
  TextareaAutosize,
  Box,
  Button,
  Container
} from "@mui/material";
import { useAuth } from "oidc-react";
import Header from "../components/Header";
import Footer from "../components/FooterTask";
import useMediaQuery from "@mui/material/useMediaQuery";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import styles from "./Notes.module.css";
import { useTranslation } from "react-i18next";

const PageTwo = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const bottomNoteRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [notesData, setNotesData] = useState([]);
  const [externalData, setExternalData] = useState({ valuE_WITH_LOV: "" });
  const [customerNotes, setCustomerNotes] = useState([]);
  const [newCustomerNote, setNewCustomerNote] = useState("");
  const [diaryData, setDiaryData] = useState([]);
  const [newDiaryText, setNewDiaryText] = useState("");
  const [newNoteText, setNewNoteText] = useState("");
  const [editNoteText, setEditNoteText] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isReadOnly, setReadOnly] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isDelayActive, setIsDelayActive] = useState(false);
  const { wono } = useParams();
  const auth = useAuth();

  const accessToken = auth.userData?.access_token;
  const preferred_username =
    auth.userData?.profile?.preferred_username?.toUpperCase();

  const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
  const apiEndpoints = {
    0: `${rootUrl}notes/customernotes/${wono}`, //Messages sent to customer.
    1: `${rootUrl}notes/internal/${wono}`,
    2: `${rootUrl}WorkOrder/parameter/${wono}/10001`, //External comment
    3: `${rootUrl}Diary/list/${wono}/TEC/Notes`,
  };

  const fetchData = useCallback(async () => {
    if (selectedTab === 2) {
      return;
    }
    try {
      const response = await fetch(apiEndpoints[selectedTab], {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        if (selectedTab === 3) { //Diary
          const data = await response.json();
          data.sort((a, b) => {
            const dateA = new Date(a.createdTime);
            const dateB = new Date(b.createdTime);
            return dateB - dateA;
          });
          setDiaryData(data);
        }
        else if (selectedTab === 0) {
          const data = await response.json();
          setCustomerNotes(data);
        } else {
          const data = await response.text();
          setNotesData(data);
        }
      } else {
        console.error("Failed to fetch notes. Status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  }, [selectedTab]);

  useEffect(() => {
    const fetchDataFromParameter = async () => {
      try {
        const response = await fetch(apiEndpoints[2], {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setExternalData(data);
        } else {
          console.error(
            "Failed to fetch parameter 10001. Status:",
            response.status
          );
        }
      } catch (error) {
        console.error("Error fetching parameter 10001:", error);
      }
    };
    fetchDataFromParameter();
  }, []);

  useEffect(() => {
    const fetchWorkOrderStatus = async () => {
      try {
        const response = await fetch(`${rootUrl}WorkOrder/status/${wono}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          const jsonData = await response.text();
          setReadOnly(jsonData === "REPORTED" || jsonData === "FINISHED");
        } else {
          console.log(
            "Error fetching work order status. Status:",
            response.status
          );
        }
      } catch (error) {
        console.log("Error:", error);
      }
    };
    fetchWorkOrderStatus();
  }, []);

  const updateWorkOrder = async (woData, type) => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");
    const options = {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(woData),
    };
    let url = `${rootUrl}WorkOrder/wo`;
    const response = await fetch(url, options);

    if (!response.ok) {
      console.log(response);
    } else {
      if (type === "CUSTOMER_NOTE") {
        setNewCustomerNote("");
        setCustomerNotes((prevCustomerNotes) => [
          {
            woNo: -1,
            pageNo: -1,
            title: "",
            noteText: "* " + woData.performeD_ACTION_LO,
          },
          ...prevCustomerNotes,
        ]);
      } else if (type === "INTERNAL_NOTE") {
        setNewNoteText("");
        setNotesData(
          (isEdit ? "" : "* ") + woData.parameteR_CATEGORIES[0].valuE_WITH_LOV
        );
      }
    }

    setIsProcessing(false);
  };

  useEffect(() => {
    fetchData();
    scrollToBottom();
  }, [selectedTab, fetchData]);

  const handleTabChange = (event, newValue) => {
    setNotesData([]);
    setSelectedTab(newValue);
  };

  const onFirstMenuItemClick = useCallback(() => {
    navigate(`/task/${wono}`);
  }, [navigate, wono]);

  const onFirstMenuItem2Click = useCallback(() => {
    navigate(`/materials/${wono}`);
  }, [navigate, wono]);

  const onFirstMenuItem3Click = useCallback(() => {
    navigate(`/attachments/${wono}`);
  }, [navigate, wono]);

  const onFirstMenuItem4Click = useCallback(() => {
    navigate(`/report/${wono}`);
  }, [navigate, wono]);

  const scrollToBottom = () => {
    bottomNoteRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const updateDiary = async (diaryData) => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");
    const options = {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(diaryData),
    };
    let url = `${rootUrl}Diary/add`;
    const response = await fetch(url, options);

    if (!response.ok) {
      console.error(response);
    } else {
      fetchData();
    }

    setIsProcessing(false);
  };

  const addDiaryText = () => {
    if (!newDiaryText) {
      return;
    }

    setIsProcessing(true);
    const diaryWoData = {
      woNo: wono,
      userId: preferred_username,
      diaryText: newDiaryText,
      userRole: "TEC",
      visible: true,
    };
    updateDiary(diaryWoData);
    setNewDiaryText("");
  };

  const addNote = () => {
    if (!newNoteText) {
      return;
    }

    setIsProcessing(true);
    const date = new Date().toLocaleString() + "";
    const text =
      preferred_username +
      " - " +
      date +
      "\n" +
      newNoteText +
      "\n\n" +
      notesData;
    const woData = {
      wO_NO: wono,
      parameteR_CATEGORIES: [
        {
          parameteR_ID: "80",
          valuE_WITH_LOV: text,
        },
      ],
    };
    updateWorkOrder(woData, "INTERNAL_NOTE");
  };

  const addNoteToCustomer = () => {
    if (!newCustomerNote || !wono) {
      return;
    }

    setIsProcessing(true);
    const woData = {
      wO_NO: wono,
      performeD_ACTION_LO: newCustomerNote,
    };
    updateWorkOrder(woData, "CUSTOMER_NOTE");
    startDelay();
  };

  const editNote = () => {
    if (!editNoteText) {
      return;
    }

    setIsProcessing(true);
    const woData = {
      wO_NO: wono,
      parameteR_CATEGORIES: [
        {
          parameteR_ID: "80",
          valuE_WITH_LOV: editNoteText,
        },
      ],
    };
    updateWorkOrder(woData, "INTERNAL_NOTE");
    setIsEdit(false);
  };

  const handleEdit = () => {
    if (isEdit) {
      setEditNoteText("");
      setIsEdit(false);
    } else {
      setEditNoteText(notesData);
      setIsEdit(true);
    }
  };

  const cleanCustomerNotes = (note) => {
    let tmpNote = note.noteText.replaceAll('£$', '').replaceAll('$£', '\n');
    return tmpNote.replace(/(^#[^\n]*#(\n|$))/gm, '');
  };

  const startDelay = () => {
    setIsDelayActive(true);
    setTimeout(() => {
      setIsDelayActive(false);
    }, 8000);
  };

  return (
    <>
      <Header />
      <Container
        style={{ overflowY: "auto", overflowX: "hidden", height: "85dvh" }}
      >
        <Tabs value={selectedTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
          <Tab label={t('workOrder.notes.events')} />
          <Tab label={t('workOrder.notes.internal')} />
          <Tab label={t('workOrder.notes.external')} sx={{ display: externalData.valuE_WITH_LOV.length === 0 ? 'none' : '' }} />
          <Tab label={t('workOrder.notes.technical')} />
        </Tabs>
        {selectedTab === 0 ? //Note to customer
          <>
            <Box className={styles.addNoteContainer}>
              <Typography variant="h6">{t('workOrder.notes.addNoteCustomer')}</Typography>
              <div>
                <TextareaAutosize
                  minRows={3}
                  placeholder={t('workOrder.notes.placeHolderText')}
                  value={newCustomerNote}
                  disabled={isReadOnly}
                  onChange={(e) => setNewCustomerNote(e.target.value)}
                  className={styles.noteTextarea}
                />
              </div>
              <Button variant="contained" color="primary" onClick={addNoteToCustomer} disabled={isReadOnly || isProcessing || isDelayActive} endIcon={<SendIcon />}>
                {t('workOrder.notes.addNoteButtonText')}
              </Button>
            </Box>
            <Box className={styles.addNoteContainer}>
              <Typography variant="h6">{t('workOrder.notes.existingNotes')}</Typography>
              <Typography variant="h2" style={{ whiteSpace: 'pre-line', fontSize: 15, wordWrap: 'break-word' }}>
                {
                  customerNotes.map((row, key) => (
                    <React.Fragment key={key}>
                      <span>{cleanCustomerNotes(row)}</span>
                      {key !== customerNotes.length - 1 && <hr style={{ border: '1px solid lightgrey' }} />}
                    </React.Fragment>
                  ))
                }
              </Typography>
            </Box>
          </>
          : <></>}
        {selectedTab === 1 ?
          <>
            <Box className={styles.addNoteContainer}>
              <Typography variant="h6">{t("workOrder.notes.addNote")}</Typography>
              <div>
                <TextareaAutosize
                  minRows={3}
                  placeholder={t("workOrder.notes.placeHolderText")}
                  value={newNoteText}
                  onChange={(e) => setNewNoteText(e.target.value)}
                  className={styles.noteTextarea}
                  disabled={isReadOnly}
                />
              </div>
              <Button
                variant="contained"
                color="primary"
                onClick={addNote}
                disabled={isReadOnly || isProcessing}
                endIcon={<SendIcon />}
              >
                {t("workOrder.notes.addNoteButtonText")}
              </Button>
            </Box>
            {notesData.length > 0 ? (
              isEdit ? (
                <Box className={styles.addNoteContainer}>
                  <Typography variant="h6">
                    {t("workOrder.notes.existingNotes")}{" "}
                    <EditIcon onClick={handleEdit} sx={{ cursor: "pointer" }} />
                  </Typography>
                  <div>
                    <TextareaAutosize
                      minRows={5}
                      value={editNoteText}
                      onChange={(e) => setEditNoteText(e.target.value)}
                      disabled={isReadOnly}
                      className={styles.noteTextarea}
                    />
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={editNote}
                    endIcon={<SendIcon />}
                    disabled={notesData === editNoteText || isReadOnly || isProcessing}
                  >
                    {t("workOrder.notes.editNoteButtonText")}
                  </Button>
                </Box>
              ) : (
                <Box className={styles.addNoteContainer}>
                  <Typography variant="h6">
                    {t("workOrder.notes.existingNotes")}{" "}
                    <EditIcon onClick={handleEdit} sx={{ cursor: "pointer" }} />
                  </Typography>
                  <Typography
                    variant="h2"
                    style={{ whiteSpace: "pre-line", fontSize: 15, wordWrap: 'break-word' }}
                  >
                    {notesData}
                  </Typography>
                </Box>
              )
            ) : (
              <></>
            )}
          </>
          : <></>}
        {selectedTab === 2 ?  //External note (Parameter 10001)
          <>
            <Box className={styles.addNoteContainer}>
              <Typography variant="h6">
                {t("workOrder.notes.existingNotes")}
              </Typography>
              <Typography variant="h2" style={{ whiteSpace: "pre-line", wordWrap: 'break-word' }}>
                {externalData.valuE_WITH_LOV === ""
                  ? t("noData")
                  : externalData.valuE_WITH_LOV}
              </Typography>
            </Box>
          </>
          : <></>}
        {selectedTab === 3 ?  //Diary
          <>
            <Box className={styles.addNoteContainer}>
              <Typography variant="h6">{t("workOrder.notes.addNote")}</Typography>
              <div>
                <TextareaAutosize
                  minRows={3}
                  placeholder={t("workOrder.notes.placeHolderText")}
                  value={newDiaryText}
                  onChange={(e) => setNewDiaryText(e.target.value)}
                  className={styles.noteTextarea}
                  disabled={isReadOnly}
                />
              </div>
              <Button
                variant="contained"
                color="primary"
                disabled={isReadOnly || isProcessing || newDiaryText.length === 0}
                onClick={addDiaryText}
                endIcon={<SendIcon />}
              >
                {t("workOrder.notes.addNoteButtonText")}
              </Button>
            </Box>
            <Box className={styles.addNoteContainer}>
              <Typography variant="h6"></Typography>
              <Typography variant="h2" style={{ whiteSpace: 'pre-line', fontSize: 15, wordWrap: 'break-word' }}>
                {
                  diaryData.map((row, key) => (
                    <React.Fragment key={key}>
                      <span><i style={{ fontSize: 'smaller' }}>{row.userId} - {row.createdTime.replace('T', ' ').slice(0, 16)}</i></span><br />
                      <span>{row.diaryText}</span>
                      {key !== diaryData.length - 1 && <hr style={{ border: '1px solid lightgrey' }} />}
                    </React.Fragment>
                  ))
                }
              </Typography>
            </Box>
          </>
          : <></>}
        <Footer
          activePage="notes"
          onFirstMenuItemClick={onFirstMenuItemClick}
          onFirstMenuItem2Click={onFirstMenuItem2Click}
          onFirstMenuItem3Click={onFirstMenuItem3Click}
          onFirstMenuItem4Click={onFirstMenuItem4Click}
          taskId={wono}
        >
        </Footer>
      </Container>
    </>
  );
};

export default PageTwo;
