import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Grid,
  ListItem,
  ListItemText,
  Box,
  CardHeader,
} from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useAuth } from "oidc-react";
import Header from "../components/Header";
import Footer from "../components/FooterTask";
import SubMaterialModal from "../components/SubMaterialModal";
import EditMaterialModual from "../components/EditMaterialModual";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import RemoveMaterialModal from "../components/RemoveMaterialModal";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const ProductMaterials = () => {
  const { t } = useTranslation();
  const [result, setResult] = useState({ earnings: 0, expense: 0 });
  const [postings, setPostings] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isRemoveModalOpen, setRemoveModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [postingToBeRemoved, setPostingToBeRemoved] = useState(null);
  const [postingToBeEdit, setPostingToBeEdit] = useState(null);
  const [currentMainMaterialId, setCurrentMainMaterialId] = useState(null);
  const [isReadOnly, setReadOnly] = useState(false);
  const { wono } = useParams();
  const auth = useAuth();
  const accessToken = auth.userData?.access_token;
  const navigate = useNavigate();
  const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
  const lineBreak = window.innerWidth <= 768 ? <br /> : "";
  const isSubcontractor = auth.userData?.profile?.is_subcontractor;
  const preferred_username = auth.userData?.profile?.preferred_username?.toUpperCase();
  const [timer, setTimer] = useState(0);
  const [counter, setCounter] = useState(-1);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (counter < 10 && counter !== -1) {
        const data = await getPostings();
        if ((data.length !== postings.length) || calculateQtySum(data) !== calculateQtySum(postings)) {
          setPostings(data);
          clearInterval(interval);
          setCounter(-1);
        } else {
          setCounter(counter + 1);
        }
      } else {
        clearInterval(interval);
        if (counter !== -1) {
          setCounter(-1);
        }
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [counter]);

  const calculateQtySum = (array) => {
    return array.reduce((accumulator, item) => {
      return accumulator + item.qty;
    }, 0);
  };

  const getPostings = async () => {
    try {
      const response = await fetch(`${rootUrl}WorkOrder/postings/${wono}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error occured in gePostings:", error);
      return [];
    }
  };

  useEffect(() => {
    const fetchWorkOrderStatus = async () => {
      try {
        const response = await fetch(`${rootUrl}WorkOrder/status/${wono}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          const jsonData = await response.text();
          setReadOnly(jsonData === "REPORTED" || jsonData === "FINISHED");
        } else {
          console.log(
            "Error fetching work order status. Status:",
            response.status
          );
        }
      } catch (error) {
        console.log("Error:", error);
      }
    };
    fetchWorkOrderStatus();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPostings();
        setPostings(data);
      } catch (error) {
        console.error("Error occured in call gePostings:", error);
      }
    };
    fetchData();
  }, [accessToken]);

  useEffect(() => {
    if (postings) {
      let earnings = 0;
      let expense = 0;
      postings.forEach((posting) => {
          earnings += posting.qtyToInvoice * posting.listPrice;
          expense += posting.amount;
      });
      setResult({ earnings: earnings, expense: expense });
    }
  }, [postings]);

  const onFirstMenuItemClick = useCallback(() => {
    navigate(`/task/${wono}`);
  }, [navigate, wono]);

  const onFirstMenuItem1Click = useCallback(() => {
    navigate(`/notes/${wono}`);
  }, [navigate, wono]);

  const onFirstMenuItem2Click = useCallback(() => {
    navigate(`/materials/${wono}`);
  }, [navigate, wono]);

  const onFirstMenuItem3Click = useCallback(() => {
    navigate(`/attachments/${wono}`);
  }, [navigate, wono]);

  const onFirstMenuItem4Click = useCallback(() => {
    navigate(`/report/${wono}`);
  }, [navigate, wono]);

  const processAPIData = (data) => {
    let mainProducts = data.filter(
      (product) => product.userDef6 === product.partId
    );
    let subProducts =
      data.filter((product) => product.userDef6 !== product.partId) ||
      ((product) => product.userDef6 == null);

    mainProducts = mainProducts.map((main) => {
      return {
        ...main,
        subMaterials: subProducts.filter((sub) => sub.userDef6 === main.partId),
      };
    });

    return mainProducts;
  };

  const handleAddMainMaterial = () => {
    setModalOpen(true);
  };

  const handleAddSubMaterial = (mainMaterialId) => {
    setCurrentMainMaterialId(mainMaterialId);
    setModalOpen(true);
  };

  const handleCloseModal = (event, reason) => {
    if (reason !== "backdropClick") {
      setModalOpen(false);
    }
  };

  const handleCloseRemoveModal = () => {
    setPostingToBeRemoved(null);
    setRemoveModalOpen(false);
  };

  const openRemoveModal = (posting) => {
    setRemoveModalOpen(true);
    setPostingToBeRemoved(posting);
  };

  const openEditModal = (posting) => {
    setEditModalOpen(true);
    setPostingToBeEdit(posting);
  };

  const handleCloseEditModal = () => {
    //setPostingToBeEdit(null);
    setEditModalOpen(false);
  };

  const isAddedFromTapp = (posting) => {
    return !posting.bookStatus;
  };

  return (
    <div>
      <Header />

      <Container
        style={{ overflowY: "auto", overflowX: "hidden", height: "70dvh" }}
      >
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="h6" color="primary">
            {t("workOrder.materials.header")}
          </Typography>
        </Box>

        {postings.some((posting) => posting.costType !== "P") ? (
          postings.map((posting) =>
            posting.costType !== "P" ? (
              <Card
                key={posting.rowNo}
                style={{ margin: "20px 0", backgroundColor: "#f7f7f7" }}
              >
                <CardHeader
                  action={
                    <>
                      {
                        posting.employeeId === preferred_username && (
                          <IconButton onClick={() => openEditModal(posting)}>
                            <EditIcon />
                          </IconButton>
                        )
                      }
                      {isAddedFromTapp(posting) && (
                      <>
                        <IconButton onClick={() => openRemoveModal(posting)}>
                          <DeleteIcon />
                        </IconButton>
                      </>)
                      }
                    </>
                  }
                  title={`${posting.catalogNo} - ${posting.lineDescription}`}
                  titleTypographyProps={{ variant: "h6" }}
                  style={{ paddingBottom: 0 }}
                />
                <CardContent>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="subtitle1" gutterBottom>
                      {t("quantity")}: {posting.qtyToInvoice}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom align="right">
                      {posting.creDate.split("T")[0]}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="subtitle1" gutterBottom>
                      {posting.cmnt}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            ) : (
              <></>
            )
          )
        ) : (
          <div style={{ textAlign: "center", color: "#404040" }}>
            {t("workOrder.materials.noProducts")}
          </div>
        )}

        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="h6" color="primary">
            {t("workOrder.materials.subHeader")}
          </Typography>
        </Box>

        {postings.map((posting) =>
          posting.costType === "P" ? (
            <Card
              key={posting.partId}
              style={{ margin: "20px 0", backgroundColor: "#f7f7f7" }}
            >
              <CardHeader
                action={
                  <>
                    {
                      posting.employeeId === preferred_username && (
                        <IconButton onClick={() => openEditModal(posting)}>
                          <EditIcon />
                        </IconButton>
                      )
                    }
                    {
                      isAddedFromTapp(posting) && (
                        <IconButton onClick={() => openRemoveModal(posting)} disabled={isReadOnly}>
                          <DeleteIcon />
                        </IconButton>
                      )
                    }
                  </>
                }
                title={
                  posting.catalogNo !== ""
                    ? posting.catalogNo
                    : t("time")
                }
                titleTypographyProps={{ variant: "h6" }}
                style={{ paddingBottom: 0 }}
              />
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="subtitle1" gutterBottom>
                    {t("quantity")}: {posting.qty}
                  </Typography>
                  {posting.amount !== null ? (
                    <Typography variant="subtitle1" gutterBottom>
                      {t("cost")}: {posting.amount ?? 0} {t("currency")}
                    </Typography>
                  ) : (
                    <></>
                  )}
                  <Typography variant="subtitle1" gutterBottom align="right">
                    {posting.creDate.split("T")[0]}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="subtitle1" gutterBottom>
                    {posting.cmnt}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          ) : (
            <></>
          )
        )}
        {postingToBeRemoved && (        
          <RemoveMaterialModal
            open={isRemoveModalOpen}
            onClose={handleCloseRemoveModal}
            wono={wono}
            posting={postingToBeRemoved}
            postings={postings}
            setPostings={setPostings}
          />
        )}
        {postingToBeEdit && 
          <EditMaterialModual
            open={isEditModalOpen}
            onClose={handleCloseEditModal}
            wono={wono}
            posting={postingToBeEdit}
            setCounter={setCounter}
          />
        }
        <SubMaterialModal
          open={isModalOpen}
          onClose={handleCloseModal}
          mainProductId={currentMainMaterialId}
          wono={wono}
          counter={counter}
          setCounter={setCounter}
          postings={postings}
        />


      </Container>
      { isSubcontractor == false && 
        <Container>
          <Card style={{ margin: "20px 0", backgroundColor: "#f7f7f7" }}>
            <CardContent>
              <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
                <Typography variant="subtitle2" style={{ marginLeft: "20px" }}>
                  {t("earnings")}: {result.earnings.toFixed(2)} - {t("cost")}:{" "}
                {result.expense.toFixed(2)}
                  {lineBreak} = {(result.earnings - result.expense).toFixed(2)}{" "}
                {t("currency")} (
                  {(
                  ((result.earnings - result.expense) / result.earnings) *
                  100
                ).toFixed(2)}{" "}
                %)
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Container>
      }
      <IconButton
        onClick={handleAddMainMaterial}
        sx={{
          position: "fixed",
          bottom: "80px",
          right: "20px",
          bgcolor: "#fff",
          borderRadius: "50%",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
        disabled={isReadOnly}
      >
        <AddIcon sx={{ fontSize: 32, color: "#333" }} />
      </IconButton>

      <Footer
        activePage="materials"
        onFirstMenuItemClick={onFirstMenuItemClick}
        onFirstMenuItem1Click={onFirstMenuItem1Click}
        onFirstMenuItem2Click={onFirstMenuItem2Click}
        onFirstMenuItem3Click={onFirstMenuItem3Click}
        onFirstMenuItem4Click={onFirstMenuItem4Click}
        taskId={wono}
      ></Footer>
    </div>
  );
};

export default ProductMaterials;