import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Typography, Button, Snackbar, Alert, Container } from "@mui/material";
import Stack from "@mui/material/Stack";
import Buttons from "../components/Buttons";
import Accordion from "../components/TaskAccordion";
import Header from "../components/Header";
import Footer from "../components/FooterTask";
import { useAuth } from 'oidc-react';
import { useTranslation } from 'react-i18next';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PortalPopup from "../components/PortalPopup"
import SelfAssignModal from "../components/SelfAssignModal";
import parse from 'html-react-parser';
import GroupIcon from '@mui/icons-material/Group';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const TaskWrapper = () => {  // Using a wrapper make task rerender when wono changes. Fixes rerouting rom one task to another task
  const { wono } = useParams();

  return <Task key={wono} wono={wono} />;
};

const Task = ({ wono }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [showSelfAssignButton, setShowSelfAssignButton] = useState(false);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const [isSelfAssignModalOpen, setSelfAssignModalOpen] = useState(false);
  const [disableSelfAssignButton, setDisableSelfAssignButton] = useState(false);
  const [toastState, setToastState] = useState({
    open: false,
    severity: 'success',
    message: '',
    autoHideDuration: 6000
  });
  const [statusChangeErrorCodes, setStatusChangeErrorCodes] = useState([]);
  const [openResourcesTooltip, setResourcesTooltipOpen] = useState(false);
  const [extraResourcesTooltipText, setExtraResourcesTooltipText] = useState('');
  
  const auth = useAuth();
  const accessToken = auth.userData?.access_token;
  const username = auth.userData?.profile?.preferred_username;
  const hasElevatedAccess = auth.userData?.profile?.has_elevated_tapp_access;

  const openSelfAssignModal = useCallback(() => {
    setSelfAssignModalOpen(true);
  }, []);

  const closeSelfAssignModal = useCallback(() => {
    setSelfAssignModalOpen(false);
  }, []);

  const handleTooltipClose = () => {
    setResourcesTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setResourcesTooltipOpen(true);
  };

  useEffect(() => {
    if (accessToken) {
      fetchData();
    }
  }, [accessToken]);

  useEffect(() => {
    setShowStatusDropdown(!showSelfAssignButton || hasElevatedAccess);
  }, [showSelfAssignButton]);

  const fetchData = async () => {
    try {
      const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
      const response = await fetch(`${rootUrl}WorkOrder/details/${wono}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (response.ok) {
        const jsonData = await response.json();
        // console.log(jsonData)
        setData(jsonData);

        if (jsonData.taskStatus !== "FINISHED" && jsonData.taskStatus !== "REPORTED") {
          if (jsonData.executedBy.toUpperCase() !== username.toUpperCase() && !(jsonData.extraResources.some(item => item.eltelId.toUpperCase() == username.toUpperCase()))) {
            setShowSelfAssignButton(true);
          }
          else {
            setShowStatusDropdown(true);
          }
        }
        else {
          // Don't show self assign button and always show status if status is FINISHED (historical) or REPORTED
          setShowSelfAssignButton(false);
          setShowStatusDropdown(true);
        }
      } else {
        console.log("Error fetching data. Status:", response.status);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const hasExtraResources = () => {
    return data && data.extraResources && data.extraResources.length > 0;
  };

  useEffect(() => {
    if (data && data.extraResources) {
      let tooltipText = data.extraResources.map(item => `${item.eltelId} - ${item.name}`).join('\n');
      setExtraResourcesTooltipText(tooltipText);
    }
  }, [data]);

  const onFirstMenuItemClick = useCallback(() => {
    navigate(`/task/${wono}`);
  }, [navigate, wono]);

  const onFirstMenuItem1Click = useCallback(() => {
    navigate(`/notes/${wono}`);
  }, [navigate, wono]);

  const onFirstMenuItem2Click = useCallback(() => {
    navigate(`/materials/${wono}`);
  }, [navigate, wono]);

  const onFirstMenuItem3Click = useCallback(() => {
    navigate(`/attachments/${wono}`);
  }, [navigate, wono]);

  const onFirstMenuItem4Click = useCallback(() => {
    navigate(`/report/${wono}`);
  }, [navigate, wono]);

  const onShowDiaryClick = useCallback(() => {
    window.open(
      `${window.location.origin}/diary/${wono}`,
      "tapp_diary"
    );
  }, [wono]);

  return (
    <div key={wono}>
      <Header />
      <Container
        style={{ overflowY: "auto", overflowX: "hidden", height: "85dvh", paddingLeft: "5px", paddingRight: "5px" }}
      >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Snackbar
          open={toastState.open}
          autoHideDuration={toastState.autoHideDuration}
          onClose={() => setToastState({ ...toastState, open: false })}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          style={{ position: 'absolute', zIndex: 1301 }}>
          <Alert
            severity={toastState.severity}
            variant="filled"
            onClose={() => setToastState({ ...toastState, open: false })}
            sx={{ whiteSpace: 'pre-line' }} >
            {parse(toastState.message)}
          </Alert>
        </Snackbar>
        <Box sx={{ padding: 2, flex: 1, marginBottom: 7 }}>
          <Box sx={{ marginBottom: 2 }}>
            <Stack direction="row" spacing={{ xs: 5, sm: 20 }}>
              <Typography variant="h6" color="primary">
                {t('workOrder.details')}
              </Typography>
              <Stack style={{ display: showSelfAssignButton ? "inline-flex" : "none" }} >
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={openSelfAssignModal}
                  endIcon={<AssignmentIndIcon />}
                  disabled={disableSelfAssignButton}>
                  {t('workOrder.assign')}
                </Button>
                <Typography style={{ color: "#E17919" }} variant="body2">{t('workOrder.assignedTo')} {data.executedBy}</Typography>
              </Stack>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={openResourcesTooltip}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={<div style={{ whiteSpace: 'pre-line' }}>{extraResourcesTooltipText}</div>}
                  >
                    <GroupIcon style={{ display: hasExtraResources() ? "inline-flex" : "none" }} onClick={handleTooltipOpen} fontSize="large" />
                  </Tooltip>
              </div>
          </ClickAwayListener>
            </Stack>
          </Box>
          <Box>
            <Typography color="textSecondary">
              {data.requestEndDt && new Date(data.requestEndDt).toLocaleString(i18n.language)}
            </Typography>
            <Typography variant="subtitle1" color="textPrimary">
              {data.taskId} - {data.taskType}
            </Typography>
            {(data.materialReceived !== null) ? 
              <>
                {
                  data.materialReceived === true ? (
                    <Typography style={{ color: "#425CFB" }}>{t('workOrder.materialReceived')}<br />{data.materialReceivedText}</Typography>
                  ) : (
                    <span style={{ color: "#E17919" }}>{t('workOrder.materialNotReceived')}</span>
                  )
                }
              </> : <></>
            }
            <Typography>
              {`${t('workOrder.moreInfo.referenceNumber')}: ${data.referenceNumber}`}
            </Typography>
          </Box>
          <Box sx={{ marginTop: 2 }}>
            <Typography variant="body2" color="textPrimary">
              {data.endUserName}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {data.endUserMobilePhone}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {data.endUserAddress1}
              <br />
              {data.endUserAddress2}
              <br />
              {data.countyName}
              <br />
            </Typography>
            {
              data.eventControl === "PENDING" ? (
                <Typography variant="body2" color="#E17919" style={{ textAlign: 'right', marginRight: 10 }}>
                  {t('workOrder.pending')}
                </Typography>
              ) : (<></>)
            }
            {
              data.customerReadyOk && data.customerReadyOk !== "" && data.taskStatus !== "WORKDONE" && data.taskStatus !== "REPORTED" ? (
                <Typography variant="body2" color={data.customerReadyOk.startsWith("Yes") ? '#2DA33B' : '#FF3737'} style={{ textAlign: 'right', marginRight: 10 }}>
                  {`${t('workOrder.customerReadyOk')}: ${data.customerReadyOk.length > 21 ? data.customerReadyOk.replace(/:\d\d$/, "") : data.customerReadyOk}`}
                </Typography>
              ) : (<></>)
            }
          </Box>
          <Buttons taskId={wono} status={data.taskStatus} phoneNumber={data.endUserMobilePhone} coordinates={data.coordinates}
            showStatusDropdown={showStatusDropdown} setToastState={setToastState} agreementType={data.agreementType}
            setStatusChangeErrorCodes={setStatusChangeErrorCodes} eventControl={data.eventControl} />
          <Accordion data={data} statusChangeErrorCodes={statusChangeErrorCodes} />
        </Box>
        <Footer
          activePage="task"
          onFirstMenuItemClick={onFirstMenuItemClick}
          onFirstMenuItem1Click={onFirstMenuItem1Click}
          onFirstMenuItem2Click={onFirstMenuItem2Click}
          onFirstMenuItem3Click={onFirstMenuItem3Click}
          onFirstMenuItem4Click={onFirstMenuItem4Click}
          onShowDiaryClick={onShowDiaryClick}
          taskId={wono}
        />
      </Box>
      {isSelfAssignModalOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeSelfAssignModal}
        >
          <SelfAssignModal onClose={closeSelfAssignModal} setShowSelfAssignButton={setShowSelfAssignButton} setDisableSelfAssignButton={setDisableSelfAssignButton} />
        </PortalPopup>
      )}
      </Container>
    </div>
  );
};

export default TaskWrapper;