import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Card, CardContent, Typography } from '@mui/material';
import { useAuth } from 'oidc-react';
import { useTranslation } from 'react-i18next';

const DataTable = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const auth = useAuth();
  const accessToken = auth.userData?.access_token;
  const { wono } = useParams();
  useEffect(() => {
    if (!accessToken) return;

    const fetchData = async () => {
      try {
        const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
        const response = await fetch(`${rootUrl}api/Eos/Partlist/${wono}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          }
        });

        if (response.ok) {
          const jsonData = await response.json();
          setData(jsonData);
        } else {
          console.error("Failed to fetch data. Status:", response.status);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [accessToken]);

  return (
    <div>
      {data.map((row, key) => (
        <Card key={key} style={{ margin: '16px 0', backgroundColor: '#f5f5f5' }}>
          <CardContent>
            <Typography variant="h7">
              {row.stdJobId}
            </Typography>
            <Typography color="textSecondary">
              {t('quantity')}: {row.quantity}
            </Typography>
            <Typography variant="body2">
              {t('description')}: {row.description}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default DataTable;
