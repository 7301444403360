import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import { statusMapping } from "../Utils.js";
import GroupIcon from "@mui/icons-material/Group";
import ExpandableText from "./ExpandableText.js";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";
import { useAuth } from "oidc-react";

const WorkorderCard = ({
  task,
  isSubcontractor,
  getSubcontractorName,
  subcontractorColleagues,
  isCalendarMode,
  updateTask,
}) => {
  const auth = useAuth();
  const accessToken = auth.userData?.access_token;
  const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
  const { t, i18n } = useTranslation();
  const [selectedSubcontractor, setSelectedSubcontractor] = useState(
    task.executedBy || ""
  );
  const navigate = useNavigate();

  const assignWorkOrder = async (eltelId) => {
    const woData = {
      wO_NO: task.id.toString(),
      worK_MASTER_SIGN_ID: eltelId,
    };
    const result = await updateWorkOrder(woData);

    if (result === true) {
      setSelectedSubcontractor(eltelId);
      updateTask({ ...task, executedBy: eltelId });
    }
  };

  const updateWorkOrder = async (woData) => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");
    const options = {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(woData),
    };
    let url = `${rootUrl}WorkOrder/wo`;
    const response = await fetch(url, options);

    if (!response.ok) {
      console.error("Error when assigning workorder");
      console.error(response);
      return false;
    } else {
      return true;
    }
  };

  const handleSubcontractorChange = (event) => {
    assignWorkOrder(event.target.value);
  };

  const handleNavigateToDetailPage = useCallback(
    (wono) => {
      navigate(`/task/${wono}`);
    },
    [navigate]
  );

  return (
    <Card
      onClick={() => handleNavigateToDetailPage(task.id)}
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        p: 2,
        position: "relative",
        borderLeft: `4px solid ${statusMapping(task.status).stateColor}`,
      }}
    >
      <CardContent sx={{ flex: "1 0 auto", padding: 0, mt: 2 }}>
        <Grid
          container
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item>
            <Typography fontSize={14} color="text.secondary" gutterBottom>
              <span>
                {t("workOrder.id")}: {task.id}
              </span>
            </Typography>
            <Typography fontSize={14} color="text.secondary" gutterBottom>
              <span>
                {t("workOrder.referenceNbr")}: {task.type}
              </span>
            </Typography>
          </Grid>
          <Grid item>
            <GroupIcon
              style={{
                display: task.hasExtraResources ? "inline-flex" : "none",
              }}
              fontSize="medium"
            />
          </Grid>
          <Grid item>
            {isSubcontractor && !isCalendarMode ? (
              task.executedBy ? (
                <Typography
                  fontSize={14}
                  color="text.secondary"
                  gutterBottom
                  textAlign={"right"}
                >
                  <span>
                    {t("workOrder.assigned")}:{" "}
                    {getSubcontractorName(task.executedBy)}
                  </span>
                </Typography>
              ) : (
                <FormControl sx={{ mb: 1, minWidth: 200 }} size="small">
                  <InputLabel>{t("workOrder.notAssigned")}</InputLabel>
                  <Select
                    value={selectedSubcontractor}
                    onChange={handleSubcontractorChange}
                    label={t("workOrder.notAssigned")}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {subcontractorColleagues.map((subcontractor) => (
                      <MenuItem
                        key={subcontractor.eltelId}
                        value={subcontractor.eltelId}
                      >
                        {subcontractor.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )
            ) : (
              <></>
            )}
            <Typography
              fontSize={14}
              color="text.secondary"
              gutterBottom
              textAlign={"right"}
            >
              <span>
                {t("workOrder.status")}: {statusMapping(task.status).stateName}
              </span>
            </Typography>
            <Typography
              fontSize={14}
              color="text.secondary"
              gutterBottom
              textAlign={"right"}
            >
              {task.eventControl === "PENDING" ? (
                <span style={{ color: "#E17919" }}>
                  {t("workOrder.pending")}
                </span>
              ) : (
                <span>
                  {task.customerBooked === true
                    ? t("workOrder.booked")
                    : t("workOrder.notBooked")}
                </span>
              )}
            </Typography>
          </Grid>
        </Grid>

        <Typography variant="h5" component="div">
          {task.title}
        </Typography>
        {task.planStartDate || task.planEndDate ? (
          <>
            <Typography
              sx={{ mb: 1, display: task.planStartDate ? "block" : "none" }}
              color="text.secondary"
            >
              {t("workOrder.planStartDate")}:{" "}
              {task.planStartDate &&
                new Date(task.planStartDate).toLocaleString(i18n.language)}
            </Typography>
            <Typography
              sx={{ mb: 1, display: task.planEndDate ? "block" : "none" }}
              color="text.secondary"
            >
              {t("workOrder.planEndDate")}:{" "}
              {task.planEndDate &&
                new Date(task.planEndDate).toLocaleString(i18n.language)}
            </Typography>
          </>
        ) : task.start || task.end ? (
          <>
            <Typography
              sx={{ mb: 1, display: task.start ? "block" : "none" }}
              color="text.secondary"
            >
              {t("workOrder.requestedStartTime")}:{" "}
              {task.start && new Date(task.start).toLocaleString(i18n.language)}
            </Typography>
            <Typography
              sx={{ mb: 1, display: task.end ? "block" : "none" }}
              color="text.secondary"
            >
              {t("workOrder.requestedEndTime")}:{" "}
              {task.end && new Date(task.end).toLocaleString(i18n.language)}
            </Typography>
          </>
        ) : (
          <></>
        )}
        <Typography variant="body2">{task.address}</Typography>
        {task.materialReceived !== null ? (
          <>
            {task.materialReceived === true ? (
              <Typography style={{ color: "#425CFB" }}>
                {t("workOrder.materialReceived")}
                <br />
                {task.materialReceivedText}
              </Typography>
            ) : (
              <span style={{ color: "#E17919" }}>
                {t("workOrder.materialNotReceived")}
              </span>
            )}
          </>
        ) : (
          <></>
        )}
        <ExpandableText text={task.internalInfo} />
      </CardContent>
    </Card>
  );
};

export default WorkorderCard;
