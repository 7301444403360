import React from "react";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const PlanningActivityCard = ({ task }) => {
  const { t, i18n } = useTranslation();

  return (
    <Card
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        p: 2,
        position: "relative",
        borderLeft: `4px solid ${task.color}`,
      }}
    >
      <CardContent sx={{ flex: "1 0 auto", padding: 0, mt: 2 }}>
        <Typography variant="h5" component="div">
          {task.title}
        </Typography>
        <Typography
          sx={{ mb: 1, mt: 1, display: task.planStartDate ? "block" : "none" }}
          color="text.secondary"
        >
          {t("workOrder.planStartDate")}:{" "}
          {task.planStartDate &&
            new Date(task.planStartDate).toLocaleString(i18n.language)}
        </Typography>
        <Typography
          sx={{ mb: 1, display: task.planEndDate ? "block" : "none" }}
          color="text.secondary"
        >
          {t("workOrder.planEndDate")}:{" "}
          {task.planEndDate &&
            new Date(task.planEndDate).toLocaleString(i18n.language)}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default PlanningActivityCard;
