import React, { useState, useCallback, useEffect } from 'react';
import {
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  InputLabel,
  Select,
  MenuItem,
  Button,
  CircularProgress, Tooltip, FormControl, TextField
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'oidc-react';
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";

const FileInput = ({ requestId, onClose, setSuccessToastState, setToastState, fetchNewFiles, type = "", docNo = -1, wono = -1, checkinFileClientSide }) => {
  const { t } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileComments, setFileComments] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [uploading, setUploading] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [docClass, setDocClass] = useState("");
  const [docClassList, setDocClassList] = useState([]);

  const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;

  const auth = useAuth();
  const accessToken = auth.userData?.access_token;
  const hasElevatedTappAccess = auth.userData?.profile.has_elevated_tapp_access;

  const handleCommentChange = (e, fileName) => {
    setFileComments((prevComments) => ({
      ...prevComments,
      [fileName]: e.target.value,
    }));
  };

  const handleFileNameChange = (e, index) => {
    let newFileName = e.target.value;
    const tmpFiles = [...selectedFiles];
    const fileToRename = tmpFiles[index];
    const currentExtension = fileToRename.name.split('.').pop();
    newFileName = `${newFileName}.${currentExtension}`;
    const renamedFile = new File([fileToRename], newFileName, { type: fileToRename.type });
    tmpFiles[index] = renamedFile;
    setSelectedFiles(tmpFiles);
  };
  
  useEffect(() => {
    const fetchDocClass = async () => {
        try {
            let url = `${rootUrl}attachment/docClass`;
            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (response.ok) {
                const data = await response.json();
                setDocClassList(data)
            }
        } catch (error) {
            console.error('Error fetching puls code parameters value:', error);
        }
    };
    if (hasElevatedTappAccess && docClassList.length === 0) {
      fetchDocClass();
    }
}, [docClassList, hasElevatedTappAccess]);

  const handleUpload = async () => {
    setUploading(true);
    const formData = new FormData();
    const newValidationErrors = {};

    selectedFiles.forEach((file) => {
      formData.append('filedata', file);
    });

    //formData.append('requestId', requestId);

    if (Object.keys(newValidationErrors).length > 0) {
      setValidationErrors(newValidationErrors);
      setUploading(false);
      return;
    }
    const docIssue = docClass === "SBIZ_B2B" ? 1 : 0;
    let url = "";
    if (type === "CHECKIN") {
      url = `${rootUrl}attachment/upload/checkin/${docNo}`;
    } else {
      url = `${rootUrl}attachment/upload/new/${wono}/${docClass}?docIssue=${docIssue}`;
    }
    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (response.ok) {
        if (type === "CHECKIN") {
          checkinFileClientSide();
        }
        else {
          fetchNewFiles();
        }

        // Update the success toast state
        setSuccessToastState({
          open: true,
          message: t('workOrder.attachments.uploadCompleted'),
        });

        handleClose();
      } else {
        console.error('Upload failed');
        setToastState({
          open: true,
          severity: 'error',
          message: t('workOrder.attachments.uploadFailed'),
        });
      }
    } catch (error) {
      console.error('Upload error:', error);
      setToastState({
        open: true,
        severity: 'error',
        message: t('workOrder.attachments.uploadFailed'),
      });
    } finally {
      setUploading(false);
    }
  };

  const handleClose = () => {
    setSelectedFiles([]);
    setFileComments({});
    setValidationErrors({});
    onClose();
  };

  const supportedFileExtensions = [
    "PDF", "DWG", "BRD", "CFG", "CLI", "RPT", "DCX", "DOCM", "DOTX", "DWF", "DXF",
    "XLSX", "XLK", "CSV", "XLS", "MSOR", "FLW", "GIF", "HDI", "HEIC", "HEICS", "HEIF",
    "HIF", "MHT", "IAL", "ICS", "KLI", "LIM", "TIF", "HTM", "HTML", "JPEG", "KMD",
    "KML", "LOG", "MDI", "DGN", "MP4", "MPP", "JPG", "NEU", "ODT", "MSG", "BMP", "PDC",
    "PJPEG", "PNG", "PPT", "PPTX", "PRN", "RTF", "SNAG", "SNP", "SOR", "SQL", "STP",
    "TXT", "TIFF", "PXY", "TST", "VC6", "VSD", "VSDX", "DOCX", "DOC", "DOT", "WVL",
    "XLSM", "XLT", "XLTM", "XLTX", "XML", "XPS", "ZIP"
  ];


  const onDrop = useCallback((acceptedFiles) => {
    const validatedFiles = [];
    const rejectedFiles = [];
    const tooLargeFiles = [];
    for (const file of acceptedFiles) {
      if (supportedFileExtensions.map(fileExtension => "." + fileExtension.toUpperCase())
        .some(fileExtension => file.name.toUpperCase().endsWith(fileExtension))) {
          // Check file size
        if (file.size > 31457280) {
          tooLargeFiles.push(file);
        }
        else {
          validatedFiles.push(file);
        }
      } else {
        rejectedFiles.push(file);
      }

      
    }
    if (rejectedFiles.length || tooLargeFiles.length) {
      let fileTypeErrMsg = `${t('workOrder.attachments.unsupportedFileType')}: ${rejectedFiles.map(file => file.name).join(', ')}`;
      let fileSizeErrMsg = `${t('workOrder.attachments.tooLargeFile')}: ${tooLargeFiles.map(file => file.name).join(', ')}`;
      let errorMsg = '';
      if (rejectedFiles.length) {
        errorMsg += fileTypeErrMsg;
      }
      if (tooLargeFiles.length) {
        if (rejectedFiles.length) {
          errorMsg += '\n';
        }
        errorMsg += fileSizeErrMsg;
      }

      setToastState({
        open: true,
        severity: 'error',
        message: errorMsg,
      });
    }
    setSelectedFiles(validatedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const handleInfoClick = (event) => {
    event.stopPropagation();
    setTooltipOpen(!tooltipOpen);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  return (
    <Paper elevation={3} sx={{ padding: 2, position: 'relative', overflowWrap: 'break-word' }}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div sx={{ marginBottom: 2 }}>
          <Button variant="contained" color="primary" component="span">
            {t('workOrder.attachments.chooseFiles')}
          </Button>
          <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{`Max filstorlek 30 MB. \n\n${t('workOrder.attachments.supportedFileTypes')}: ${supportedFileExtensions.join(', ')}`}</div>}
            open={tooltipOpen}
            onClose={handleTooltipClose}
            leaveTouchDelay={10000}>
            <IconButton onClick={handleInfoClick}>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {selectedFiles.length > 0 && (
        <div>
          <List>
            {selectedFiles.map((file, index) => (
              type === "CHECKIN" ? (
                <ListItem key={index}>
                  <ListItemText primary={file.name} />
                </ListItem>
              ) :(
                <ListItem key={index}>
                  <TextField
                    value={file.name.substring(0, file.name.lastIndexOf('.'))}
                    onChange={(e) => handleFileNameChange(e, index)}
                    fullWidth
                  />.{file.name.split('.').pop()}
                </ListItem>
              )
            ))}
          </List>
          {
            hasElevatedTappAccess ? (
              <FormControl fullWidth>
              <InputLabel id="select-label">{t('workOrder.attachments.docClassSelect')}</InputLabel>
              <Select
                labelId="select-label"
                value={docClass}
                label={t('workOrder.attachments.docClassSelect')}
                onChange={(e) => { setDocClass(e.target.value) }}
              >
                {Object.entries(docClassList).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {key} - {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            ) : (
            <FormControl fullWidth>
              <InputLabel id={'label-to-customer'}>{t('workOrder.attachments.sendToCustomer')}</InputLabel>
              <Select
                labelId={'label-to-customer'}
                label={t('workOrder.attachments.sendToCustomer')}
                id={'select-to-customer'}
                value={docClass}
                onChange={(e) => { setDocClass(e.target.value) }}
                disabled={false}
              >
                <MenuItem key={1} value={"SBIZ_B2B"}>{t('workOrder.attachments.sendToCustomerYes')}</MenuItem>
                <MenuItem key={2} value={"SPR01"}>{t('workOrder.attachments.sendToCustomerNo')}</MenuItem>
              </Select>
            </FormControl>
            )
          }
          <Box sx={{ marginTop: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpload}
              disabled={uploading || docClass === ""}
            >
              {uploading ? <CircularProgress size={24} /> : t('workOrder.attachments.upload')}
            </Button>
          </Box>
        </div>
      )}
    </Paper>
  );
};

export default FileInput;
