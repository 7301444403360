import React, { useState, useEffect } from 'react';
import {
    Button, Dialog, DialogTitle, DialogContent,
    DialogActions, FormControl, RadioGroup, FormControlLabel, Radio, CircularProgress
} from '@mui/material';
import FileInput from './FileInput';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'oidc-react';

const FileWorker = ({ openFileWorker, handleOpenFileWorker, handleCloseFileWorker, 
                        file, setSuccessToastState, fetchNewFiles, onClose, setToastState,
                        checkoutFileClientSide, undoCheckoutFileClientSide,
                        checkinFileClientSide, isReadOnly }) => {
    const [selectedValue, setSelectedValue] = useState('o1');
    const [downloading, setDownloading] = useState(false);
    const { t } = useTranslation();

    const auth = useAuth();
    const accessToken = auth.userData?.access_token;
    const hasElevatedTappAccess = auth.userData?.profile.has_elevated_tapp_access;
    const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;

    useEffect(() => {
        // console.log("file ---> " + selectedValue);
    }, [selectedValue]);

    const handleOpen = () => {
        handleOpenFileWorker();
    };

    async function getFile() {
        let filename = '';
        setDownloading(true);

        let url = `${rootUrl}attachment?wono=${file.woNo}&docNo=${file.docNo}`;

        fetch(url, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const disposition = response.headers.get('Content-Disposition');
                filename = disposition.split(/\*=(.+)/)[1];
                if (filename.toLowerCase().startsWith("utf-8''")) 
                    filename = decodeURIComponent(filename.replace(/utf-8''/i, ''));
                else 
                    filename = filename.replace(/['"]/g, '');
                return response.blob();
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${filename}`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch((error) => {
                console.error('Download error:', error);
                setToastState({
                    open: true,
                    severity: 'error',
                    message: t('workOrder.attachments.downloadFailed'),
                });
            })
            .finally(() => {
                setDownloading(false);
                handleCloseFileWorker();
            });
    }

    async function editFile() {
        let filename = '';
        setDownloading(true);

        let url = `${rootUrl}attachment/${file.docNo}/edit`;

        fetch(url, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const disposition = response.headers.get('Content-Disposition');
                filename = disposition.split(/\*=(.+)/)[1];
                if (filename.toLowerCase().startsWith("utf-8''"))
                    filename = decodeURIComponent(filename.replace(/utf-8''/i, ''));
                else
                    filename = filename.replace(/['"]/g, '');
                return response.blob();
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${filename}`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);

                checkoutFileClientSide(file);
            })
            .catch((error) => {
                console.error('Checkout error:', error);
                setToastState({
                    open: true,
                    severity: 'error',
                    message: t('workOrder.attachments.checkOutFailed'),
                });
            })
            .finally(() => {
                setDownloading(false);
                handleCloseFileWorker();
            });
    }

    async function undoCheckoutFile() {
        setDownloading(true);
        let url = `${rootUrl}attachment/${file.docNo}/undo`;
        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        })
        .then((response) => {
            if (response.ok) {
                undoCheckoutFileClientSide(file);
                setSuccessToastState({
                    open: true,
                    message: t('workOrder.attachments.checkoutUndone'),
                });
            }
            else {
                setToastState({
                    open: true,
                    severity: 'error',
                    message: t('workOrder.attachments.undoCheckoutError'),
                });
            }
        })
        .catch((error) => {
            console.error('Undo checkout error:', error);
            setToastState({
                open: true,
                severity: 'error',
                message: t('workOrder.attachments.undoCheckoutError'),
            });
        })
        .finally(() => {
            setDownloading(false);
            handleCloseFileWorker();
        });
    };

    async function detachFile() {
        setDownloading(true);
        let url = `${rootUrl}attachment/${file.woNo}/${file.docNo}/detach`;
        fetch(url, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        })
        .then((response) => {
            if (response.ok) {
                setSuccessToastState({
                    open: true,
                    message: t('workOrder.attachments.detachCompleted'),
                });
                fetchNewFiles();
            }
            else {
                setToastState({
                    open: true,
                    severity: 'error',
                    message: t('workOrder.attachments.detachFailed'),
                });
            }
        })
        .catch((error) => {
            console.error('Undo checkout error:', error);
            setToastState({
                open: true,
                severity: 'error',
                message: t('workOrder.attachments.detachFailed'),
            });
        })
        .finally(() => {
            setDownloading(false);
            handleCloseFileWorker();
        });
    };

    const handleRun = () => {
        if (selectedValue === 'o1') { // Show file
            getFile();
        }
        else if (selectedValue === 'o2') { // Edit file
            editFile();
        }
        else if (selectedValue === 'o4') { // Undo checkout
            undoCheckoutFile();
        } 
        else if (selectedValue === 'o5') { // Detach file
            detachFile();
        }
        else {
            handleCloseFileWorker();
        }
    };

    const handleClose = (event, reason) => {
        if (reason !== "backdropClick") {
            handleCloseFileWorker();
        }
    };

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };
    return (
        <div>
            <Dialog open={openFileWorker} onClose={handleClose} fullWidth maxWidth="xs" style={{ borderRadius: '25px' }}>
                <DialogTitle>{t('workOrder.attachments.fileWorkerHeader')} {file.docTitle}?</DialogTitle>
                <DialogContent>
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="options"
                            name="options"
                            value={selectedValue}
                            onChange={handleChange}
                        >
                            <FormControlLabel
                                value="o1"
                                control={<Radio />}
                                label={t('workOrder.attachments.showFile')}
                            />
                            {
                                file.docState === "Checked In" ? (
                                    <FormControlLabel
                                        value="o2"
                                        control={<Radio />}
                                        disabled={isReadOnly}
                                        label={t('workOrder.attachments.editFile')}
                                    />
                                ) : (
                                    <>
                                        <FormControlLabel
                                            value="o3"
                                            control={<Radio />}
                                            label={t('workOrder.attachments.checkInFile')}
                                        />
                                        {selectedValue === "o3" &&
                                            <FileInput requestId={file.woNo} docNo={file.docNo} type="CHECKIN"
                                                setSuccessToastState={setSuccessToastState} setToastState={setToastState}
                                                fetchNewFiles={fetchNewFiles} onClose={handleCloseFileWorker}
                                                checkinFileClientSide={checkinFileClientSide} />
                                        }
                                        <FormControlLabel
                                            value="o4"
                                            control={<Radio />}
                                            label={t('workOrder.attachments.cancelCheckOut')}
                                        />
                                    </>
                                )
                            }
                            {
                                hasElevatedTappAccess &&
                                    <FormControlLabel
                                        value="o5"
                                        control={<Radio />}
                                        label={t('workOrder.attachments.detach')}
                                    />
                            }
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {t('close')}
                    </Button>
                    <Button onClick={handleRun} color="primary">
                        {downloading ? <CircularProgress size={24} /> : t('run')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default FileWorker