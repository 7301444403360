import React, { useState, useEffect, useCallback } from "react";
import { Box, Stack, Typography, CardContent } from "@mui/material";
import { useAuth } from 'oidc-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const HistoricalWorkorderList = ({ wono }) => {
    const auth = useAuth();
    const accessToken = auth.userData?.access_token;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [historicalWoList, setHistoricalWoList] = useState([]);
    const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;

    useEffect(() => {
        const fetchHistoricalWorkorders = async () => {
            try {
                const response = await fetch(`${rootUrl}WorkOrder/historical/${wono}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                if (response.ok) {
                    const jsonData = await response.json();
                    setHistoricalWoList(jsonData);
                } else {
                    console.error("Error fetching data. Status:", response.status);
                }
            } catch (error) {
                console.error("Error:", error);
            }
        };
        fetchHistoricalWorkorders();
    }, []);

    const handleNavigateToDetailPage = useCallback((cardId) => {
        navigate(`/task/${cardId}`);
      }, [navigate]);

    return (
        <Box sx={{ marginTop: -3 }}>
            <Stack>
                {historicalWoList.length > 0 ? (
                    historicalWoList.map((row, index) => (
                        <CardContent key={index} onClick={() => handleNavigateToDetailPage(row.woNo)}
                                    sx={{ border: '1px solid lightgray', borderRadius: '10px', margin: '15px', cursor: 'pointer' }}>
                            <Typography variant="subtitle1" style={{ marginBottom: 8 }}>
                            {t('workOrder.realEndDate')}: {row.realEndDate.replace('T', ' ')}
                            </Typography>
                            <Typography variant="body1">{t('workOrder.id')}: {row.woNo}</Typography>
                            <Typography variant="body1">{row.title}</Typography>
                        </CardContent>
                    ))
                ) : (<Typography variant="body2" mt={1}>{t('workOrder.noHistoricalWorkorders')}</Typography>)}
            </Stack>
        </Box>
    );
}

export default HistoricalWorkorderList